/* eslint-disable dot-notation */
import { Vue } from 'vue-property-decorator'
import { Subject } from 'rxjs'
import AlertModal from '@/components/modal/AlertModal.vue'
import { AsyncComponent, ComponentOptions } from 'vue'
import { Proto } from '@/player/model/proto'
import { fromPromise } from 'rxjs/internal-compatibility'
import { PlayerStoreService } from '@/player/service/store.service'
import { StoreKey } from '@/player/model/constant'
import { mergeMap } from 'rxjs/operators'
import { PlayerApiService } from '@/player/service/api.service'
import app from '@/main'
import { BrowserService } from '@/service/browser.service'
import { GTAGService } from '@/service/gtag.service'

export abstract class AbstractVue extends Vue {
  protected unsubscribe$ = new Subject<void>()

  protected modals: string[] = []

  showModal(
    name: string,
    component: typeof Vue | ComponentOptions<Vue> | AsyncComponent,
    componentProps?: object,
    modalProps?: object,
    modalEvents?: object) {
    this.modals.push(name)
    componentProps = componentProps ?? {}
    componentProps['name'] = name
    modalProps = modalProps ?? {}
    modalProps['name'] = name
    modalProps['adaptive'] = true
    if (modalProps['scrollable'] === undefined) {
      modalProps['scrollable'] = true
    }
    if (modalProps['height'] === undefined) {
      modalProps['height'] = 'auto'
    }
    modalEvents = modalEvents ?? {}
    const closed = modalEvents['closed']
    modalEvents['closed'] = () => {
      const index = this.modals.indexOf(name)
      if (index >= 0) {
        this.modals.splice(index, 1)
        if (closed) {
          closed()
        }
      }
    }
    this.$modal.show(component, componentProps, modalProps, modalEvents)
  }

  showAlert(error: Error, closed?: () => void) {
    if (BrowserService.isCrawler()) {
      if (closed) {
        closed()
      }
      return
    }
    const name = AlertModal.NAME + '-' + Math.random()
    this.showModal(name, AlertModal, {
      error
    }, undefined, {
      closed
    })
  }

  showAlertMessage(msg: string, title?: string, closed?: () => void) {
    const name = AlertModal.NAME + '-' + Math.random()
    this.showModal(name, AlertModal, {
      title,
      message: msg
    }, undefined, {
      closed
    })
  }

  destroyed() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()

    const names = this.modals
    this.modals = []
    for (const name of names) {
      this.$modal.hide(name)
    }
  }

  handleOauthResponse() {
    const oauthError = this.$route.query['oauth-error'] as string
    const oauthToken = this.$route.query['oauth-token'] as string
    const oauthEmail = this.$route.query['oauth-email'] as string
    const oauthType = this.$route.query['oauth-type'] as string
    if (oauthError) {
      if (oauthError === 'duplicate') {
        this.showAlertMessage(this.$tc('player.text.modal.join.duplicate-oauth'))
      } else if (oauthError === 'other') {
      } else if (oauthError === 'no-login') {
        this.showAlertMessage(this.$tc('player.text.modal.join.no-oauth'))
      } else if (oauthError === 'scope') {
        this.showAlertMessage(this.$tc('player.text.modal.join.oauth-scope-error'))
      } else if (oauthError === 'admin-duplicate') {
        this.showAlertMessage(this.$tc('player.text.modal.join.admin-duplicate-oauth'))
      }
      const query = Object.assign({}, this.$route.query)
      delete query['oauth-error']
      this.$router.replace({
        query
      })
    } else if (oauthToken && oauthEmail) {
      const req = Proto.LoginRequest.create({
        userId: oauthEmail,
        jwt: oauthToken
      })
      fromPromise(PlayerStoreService.default()
        .getItem(StoreKey.UUID + '_' + oauthEmail)
        .catch(e => {
          this.$log.error(e)
          return Promise.resolve('')
        }))
        .pipe(
          mergeMap(uuid => {
            req.uuid = uuid as string
            const toOauthType = oauthType ? parseInt(oauthType, 10) : 0
            return PlayerApiService.login(req, undefined, toOauthType)
          })
        )
        .subscribe(() => {
          app.$bvToast?.toast(this.$tc('player.text.modal.join.success_login'), {
            solid: true,
            toaster: 'b-toaster-bottom-right',
            appendToast: true,
            noCloseButton: true,
            headerClass: 'd-none',
            bodyClass: 'toaster-login',
            toastClass: 'toast-show'
          })
        }, e => {
          this.$log.error(e)
          this.showAlert(e)
        })
    }
    if (oauthToken || oauthEmail) {
      const query = Object.assign({}, this.$route.query)
      delete query['oauth-token']
      delete query['oauth-email']
      delete query['oauth-type']
      this.$router.replace({
        query
      })
    }
  }

  gtagEvent(): void {
    try {
      if (GTAGService.isEnabled()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const gtag = (window as any).gtag
        if (!gtag) {
          return
        }
        const params: string[] = []
        // eslint-disable-next-line prefer-rest-params
        for (let i = 0; i < arguments.length; ++i) {
          // eslint-disable-next-line prefer-rest-params
          params.push(arguments[i])
        }
        gtag.bind(gtag, ...params)()
      }
    } catch (e) {
      this.$log.debug(e)
    }
  }

  attachLinkTag(msg: string, notEncode?: boolean, onlyHttp?: boolean): string {
    if (!msg) {
      return ''
    }
    let html = notEncode ? (msg || '') : BrowserService.encodeHtml(msg || '')
    try {
      html = html.replace(/(([\w-\\.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3})|((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+))/gm, function (url) {
        let href = url
        if (/^[\w-\\.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(url)) {
          href = `mailto:${url}`
        } else if (url.indexOf('//') < 0) {
          if (onlyHttp) {
            return url
          }
          href = 'https://' + url
        }
        return `<a style="word-break: break-all;" href="${href}" target="_blank">${url}</a>`
      })
    } catch (e) {
      html = html.replace(/((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+)/gm, '<a style="word-break: break-all;" href="$1" target="_blank">$1</a>')
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this as any).$sanitize(html, {
      allowedTags: ['a', 'span'],
      allowedAttributes: {
        span: ['id', 'data-uid', 'data-id', 'data-user-uid', 'class'],
        a: ['href', 'target', 'style', 'class']
      }
    })
  }

  htmlIsEmpty(html?: string | null): boolean {
    if (!html) {
      return true
    }
    try {
      const el = document.createElement('div')
      el.innerHTML = this.sanitizeHtml(html)
      return !(el.textContent || el.innerText).trim()
    } catch (ignore) {
    }
    return false
  }

  sanitizeHtml(html: string): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (this as any).$sanitize(html, {
      allowedTags: ['a', 'p', 'span', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'b', 'i', 'u', 'em', 'strong', 'strike', 'sub', 'sup', 'hr', 'br', 'blockquote', 'caption', 'code', 'pre', 'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'img'],
      allowedAttributes: {
        '*': ['id', 'class', 'style', 'src', 'href', 'target', 'rel', 'data-block']
      }
    })
  }
}
